import { Controller } from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {
  static values = {
    url: String
  }

  static  targets = [
    'actionPoint',
    'modal'
  ]

  submit(e) {
    let form = e.currentTarget.closest('form');
    let modal = $('#modalActionPoint');
    form.requestSubmit();
    form.reset();
    modal.modal('hide');
  }

  hideModal(e) {
    setTimeout(() => {
      $('#modalActionPoint').modal('hide');
    }, 100);
  }

  add(){
    fetch(this.urlValue)
      .then(response => response.text())
      .then((data) => {
        this.modalTarget.innerHTML = data;
        $('#modalActionPoint').modal('show');
      });
  }

  edit({ params: { url } }){
    fetch(url)
      .then(response => response.text())
      .then((data) => {
        this.modalTarget.innerHTML = data;
        $('#modalActionPoint').modal('show');
      });
  }
}
