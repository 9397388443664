/* eslint no-console:0 */
import GroupedBarChart from 'charts/GroupedBarChart';
import 'popper.js';
import 'bootstrap';
import $ from "jquery";
import * as d3 from 'd3';
import '../controllers'

import RadarChart from 'charts/RadarChart';
import {tableFilter} from "../resources/admin/tableFilter";

function handleConfirm(message, element) {
  let $dialog = $('#confirmation-modal')
  let $content = $dialog.find('#modal-content')
  let $ok = $dialog.find('#ok-button');
  let $cancel = $dialog.find('#cancel-button');
  $('.modal-backdrop').css('z-index', '1060');
  $dialog.css('z-index', '1070');
  $content.text(message);

  $ok.click(function(event) {
    event.preventDefault();
    $dialog.modal("hide");
    Rails.confirm = function() { return true }
    element.click();
    Rails.confirm = handleConfirm
    $ok.off();
  });

  $cancel.click(function(event) {
    $('.modal-backdrop').css('z-index', '');
    $cancel.off();
  });

  $dialog.modal("show");
  return false;
}

Rails.confirm = handleConfirm;

$(document).on('hidden.bs.modal', '.modal', function () {
  // Check if there are any other open modals on the page
  var $openModals = $('.modal:visible');
  if ($openModals.length) {
    // If there are open modals, prevent Bootstrap from removing the "modal-open" class
    $('body').addClass('modal-open');
    $('body').css('padding-right', '14px');
  }
});

const renderCharts = () => {
  [...document.querySelectorAll('.chart.grouped-barchart')].forEach((element) => {
    if (element.dataset.scores !== undefined) {
      new GroupedBarChart(element, JSON.parse(element.dataset.scores));
    }
  });

  let mySpiderChartElement = document.getElementById('mySpiderChart')
  if (mySpiderChartElement) {
    let data = JSON.parse(mySpiderChartElement.dataset.datasets);

    let radarChartOptions2 = {
      w: 600,
      h: 600,
      margin: { top: 100, right: 100, bottom: 100, left: 120 },
      maxValue: 4,
      levels: 6,
      roundStrokes: false,
      color: d3.scaleOrdinal().range(["#e6771f", "#3066b0"]),
      format: ',.2f'
    };
    RadarChart("#mySpiderChart", data, radarChartOptions2);
  }
}

const userLocalePath = () => {
  if (document.getElementById("user_locale") !== null &&
    document.getElementById('user_locale_path') !== null &&
    document.getElementById("user_locale_path") !== null) {

    const items = JSON.parse(document.getElementById('user_locale_path').dataset.json);

    document.getElementById("user_locale").addEventListener('change', function () {
      change_path_select();
    });

    function change_path_select() {
      document.getElementById("user_locale_path").innerHTML = items[document.querySelector("#user_locale option:checked").value];
    }

    change_path_select();
  }
}

function toggleClass(element, className, add) {
  element.classList.toggle(className, add);
}

function updateLinkHref(link) {
  const measurementUrl = localStorage.getItem('measurement_url');
  if (measurementUrl !== null) {
    link.href = measurementUrl;
    localStorage.removeItem('measurement_url');
  }
}

document.addEventListener('turbolinks:load', function () {

  Promise.resolve().then(renderCharts);
  userLocalePath();
  $('[data-toggle="tooltip"]').tooltip();
});

document.addEventListener('turbolinks:load', function() {
  tableFilter(`/admin/accounts/filter`, 'accounts');
  tableFilter(`/admin/users/filter`, 'users');
  tableFilter(window.location.href, 'institutions');
});
