export function tableFilter(path, target) {
  const search = document.getElementById(`${target}-search`);
  const region = document.getElementById(`${target}-region`);
  let debounceTimeout;

  if (search === null) return;

  const debounce = (func, delay) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      func();
    }, delay);
  };

  function institutionsPath() {
    const url = new URL(path);

    if (url.search !== '') {
      return `${url.pathname}/institutions/filter${url.search}&query=${search.value}&region=${region.value}`;
    } else {
      return `${url.pathname}/institutions/filter?query=${search.value}&region=${region.value}`;
    }
  }

  const handleInput = function (e) {
    debounce(() => {
      let requestPath;
      if(target === 'institutions') {
        requestPath = institutionsPath();
      } else{
        requestPath = `${path}/?query=${search.value}&region=${region.value}`;
      }

      fetch(requestPath, {
        method: "GET",
        headers: {
          "Content-Type": "text/html",
        },
      })
        .then(function (response) {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then(function (data) {
          const tbody = document
            .getElementById(`${target}-table`)
            ?.querySelector("tbody");
          if (tbody) tbody.innerHTML = data;
        })
        .catch(function (error) {
          console.error("Error:", error);
        });
    }, 300); // Adjust the delay (in milliseconds) for debounce as needed
  };

  search.addEventListener("input", handleInput);
}
