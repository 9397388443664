import * as _d3 from 'd3';
import _d3Tip from 'd3-tip';
const d3 = {..._d3, tip: _d3Tip};

export default class {
  constructor(element, data, print= false) {
    this.draw(element, data, print)
  }

  draw(element, data, print) {
    if(data.flatMap((d) => d.values.map((dv) => dv.grpValue)).every((d) => d === null))
      return;

    let tip = d3.tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0])
      .html(function(d) {
        return parseFloat(d.grpValue).toFixed(2);
      });

    let grosssWidth = print ? 600 : 800;
    let margin = {top: 20, right: 20, bottom: 60, left: 40},
      width = grosssWidth - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    let x0  = d3.scaleBand()
      .rangeRound([0, width])
      .padding(.1);

    let x1  = d3.scaleBand();
    let y   = d3.scaleLinear()
      .rangeRound([height, 0]);

    let xAxis = d3.axisBottom().scale(x0)
      .tickValues(data.map(d=>d.key));

    let yAxis = d3.axisLeft().scale(y);

    const color = d3.scaleOrdinal(d3.schemeCategory10);
    let svg = d3.select(element).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    let g = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    g.call(tip);
    let categoriesNames = data.map(function(d) { return d.key; });
    let rateNames       = data[0].values.map(function(d) { return d.grpName; });

    x0.domain(categoriesNames);
    x1.domain(rateNames)
      .rangeRound([0, x0.bandwidth()]);

    y.domain([0, d3.max(data, function(key) { return d3.max(key.values, function(d) { return d.grpValue; }); })]);

    g.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    g.append("g")
      .attr("class", "y axis")
      .style('opacity','0')
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .style('font-weight','bold')
      .text("Value");

    if(!print) {
      g.select('.y').transition().duration(500).delay(1300).style('opacity', '1');
    } else {
      g.select('.y').style('opacity', '1');
    }

    var slice = g.selectAll(".slice")
      .data(data)
      .enter().append("g")
      .attr("class", "g")
      .attr("transform",function(d) { return "translate(" + x0(d.key) + ",0)"; });

    slice.selectAll("rect")
      .data(function(d) { return d.values; })
      .enter().append("rect")
      .attr("width", x1.bandwidth())
      .attr("x", function(d) { return x1(d.grpName); })
      .style("fill", function(d) { return color(d.grpName) })
      .attr("y", function(d) { return y(0); })
      .attr("height", function(d) { return height - y(0); })
      .on("mouseover", function(d) {
        d3.select(this).style("fill", d3.rgb(color(d.grpName)).darker(2));
        tip.show(d, this);
      })
      .on("mouseout", function(d) {
        d3.select(this).style("fill", color(d.grpName));
        tip.hide(d);
      });

    if(print) {
      slice.selectAll("rect")
        .attr("y", function(d) { return y(d.grpValue); })
        .attr("height", function(d) { return height - y(d.grpValue); });
    } else {
      slice.selectAll("rect")
        .transition()
        .delay(function (d) {return Math.random()*1000;})
        .duration(1000)
        .attr("y", function(d) { return y(d.grpValue); })
        .attr("height", function(d) { return height - y(d.grpValue); });
    }

    //Legend
    let legend = svg.selectAll(".legend")
      .data(data[0].values.map(function(d) { return d.grpName; }).reverse())
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) => `translate(${i % data[0].values.length * 100}, ${height + margin.bottom})` )
      .style("opacity","0");

    legend.append("rect")
      .attr("x", 38)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d) => color(d));

    legend.append("text")
      .attr("x", 34)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .text((d) => d);

    if(!print) {
      legend.transition().duration(500).delay(function(d, i){ return 1300 + 100 * i; }).style("opacity", "1");
    } else {
      legend.style("opacity", "1");
    }
  }
}